








import { Component, Vue } from "vue-property-decorator";

@Component
export default class HomeCountPlayers extends Vue {
  playerCount: number = 0;

  created() {
    this.pullPlayerCount();
  }

  pullPlayerCount() {
    fetch("https://hexango.vxm.pl/SiteApi/getPlayersCount.php")
      .then(response => response.text())
      .then(data => {
        this.playerCount = Number.parseInt(data);
      });
  }
}
